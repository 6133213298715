import React, { useState, useCallback } from "react";
import { Checkbox } from "@shopify/polaris";
import DropdownForm from "./selectForm/DropdownForm";
import TextFieldForm from "./textFieldForm/TextFieldForm";
import { getHRtime } from "./utils/helper";
import ErrorBoundary from "../ErrorBoundary";
import styles from "./Guide.module.css";

import "./index.css"

import PropTypes from 'prop-types';
import './index.css'

const Guide = ({ select, setSelect, setModified,paused, setPaused }) => {
  const [timeZone, setTimeZone] = useState({
    value: "default",
    label: "Coordinated Universal Time (UTC)",
    id: "default",
  });
  const handleSelectChange = ({ fieldName, item }) => {
    setModified(true)
    setSelect((prevState) => {
      const selectedDate = prevState[fieldName];
      if (selectedDate === "*") {
        return {
          ...prevState,
          [fieldName]: item.value,
        };
      }

      const dateList = selectedDate.split(",");
      const found = dateList.find((date) => date === item.value);
      if (found) {
        const res = dateList.filter((date) => date !== found);
        if (!res.length) {
          return {
            ...prevState,
            [fieldName]: "*",
          };
        }
        return {
          ...prevState,
          [fieldName]: res.join(),
        };
      }

      const res = `${selectedDate},${item.value}`;
      return {
        ...prevState,
        [fieldName]: res,
      };
    });
  };

  const handleTimeChange = useCallback((value) => {
    if (value) {
      // console.log(value)
      setModified(true)
      setSelect((prevState) => {
        return {
          ...prevState,
          ["min"]: value.format("mm"),
          ["hour"]: value.format("HH"),
        };
      });
      //handleSelectChange({fieldName: "min",item:{"value": value.format('mm')}});
      //handleSelectChange({fieldName: "hour",item:{"value": value.format('HH')}})
    } else {
      handleClear({ fieldName: "min" });
      handleClear({ fieldName: "hour" });
    }
  }, []);

  const handleClickClose = ({ fieldName, item }) => {
    setSelect((prevState) => {
      const selectedDate = prevState[fieldName];
      // can be one or several date
      const dateList = selectedDate.split(",");
      const found = dateList.find((date) => date === item.value);
      if (found) {
        const res = dateList.filter((date) => date !== found);
        if (!res.length) {
          return {
            ...prevState,
            [fieldName]: "*",
          };
        }
        return {
          ...prevState,
          [fieldName]: res.join(),
        };
      }
    });
  };

  const handleClear = ({ fieldName }) => {
    setModified(true)
    for (const name of fieldName) {
      setSelect((prevState) => {
        return {
          ...prevState,
          [name]: "*",
        };
      });
    }
  };

  const handlePause =(value)=>{
    setModified(true);
    setPaused(value)
  }



  return (
    <div className={styles.guide} >
      <ErrorBoundary>
        <TextFieldForm select={select} />
      </ErrorBoundary>
      <Checkbox label="Pause schedule" checked={paused} onChange={handlePause} />
      <div className="guide__divider" />
      <ErrorBoundary>
        <DropdownForm
          handleClickClose={handleClickClose}
          handleClear={handleClear}
          handleChange={handleSelectChange}
          handleTimeChange={handleTimeChange}
          select={select}
        />
      </ErrorBoundary>
      
      <div className="guide__divider" />
    </div>
  );
};

Guide.propTypes = {
  select: PropTypes.shape({
      min: PropTypes.string,
      hour: PropTypes.string,
      dom: PropTypes.string,
      mon: PropTypes.string,
      dow: PropTypes.string
    }),
    setSelect: PropTypes.func, 
    paused : PropTypes.bool, 
    setPaused: PropTypes.func      
};

export default Guide;
export { getHRtime };
