export type ScheduleType = {
    min: string;
    hour: string;
    dom: string;
    mon: string;
    dow: string;
  };
  
export type GoogleType = {
    id: string|null;
    sheetId: string|null;
    range: string|null;
    sheetName: string|null;
    url: string|null;
    thumpnail : string|null;
  };

export let GoogleDefault ={
  id:"",
  sheetId:"",
  range:"",
  sheetName:"",
  url:null,
  thumpnail:""}


export type ShopDataType = {
    name: string;
    schedule: ScheduleType;
    paused: boolean;
    email: string;
    google: GoogleType;
    subscription: [any];
    url: string;
  };  

// export const ShopDataDefault: ShopDataType = {
//     name: "",
//     schedule: {min: '*', hour: '*', dom: '*', mon: '*', dow: '*'},
//     paused: false,
//     email: "",
//     google: {
//       id: null,
//       sheetId: null,
//       range: null,
//       sheetName: null,
//       url: null,
//       },
//     url: "",
//     subscription: [null],
//   }          