import { ASTERISK, ONE_NUMBER, SEVERAL_NUMBERS } from "./types";

//export const months = require("../selectForm/month.json");
//export const daysofweek = require("../selectForm/daysofweek.json");

export const convertToCronSyntax = (select) => {
  const splitted = "* * * * *".split(" ");
  const { min, hour, dow, dom, mon } = select;
  if (min !== "*") {
    splitted[0] = min;
  }
  if (hour !== "*") {
    splitted[1] = hour;
  }
  if (dom !== "*") {
    splitted[2] = dom;
  }
  if (mon !== "*") {
    splitted[3] = mon;
  }
  if (dow !== "*") {
    splitted[4] = dow;
  }
  return splitted.join(" ");
};

const CronToObject = (str) => {
  const arr = str.split(",");
  if (arr[0] === "*") {
    return {
      type: ASTERISK,
      length: arr.length,
      value: arr,
    };
  }
  if (isNaN(arr[0])) {
    // means this value is timezone
    return {
      type: ASTERISK,
      length: arr.length,
      value: arr,
    };
  }
  if (arr.length === 1) {
    const value = arr.map((item) => {
      if (item.length === 2 && item[0] === "0") {
        // means user formatted time differenctly like this 01 , 02
        const newItem = item[1];
        return newItem;
      }
      return item;
    });
    return {
      type: ONE_NUMBER,
      length: arr.length,
      value,
    };
  } else if (arr.length > 1) {
    const value = arr.map((item) => {
      if (item.length === 2 && item[0] === "0") {
        // means user formatted time differenctly like this 01 , 02
        const newItem = item[1];
        return newItem;
      }
      return item;
    });

    return {
      type: SEVERAL_NUMBERS,
      length: arr.length,
      value,
    };
  } else {
    throw Error("Bad Settings");
  }
};

const formatHour = (hourStr) => {
  if (typeof hourStr !== typeof "") throw Error("Bad arg");

  let intHour = Number(hourStr);
  return intHour > 12 ? `${intHour - 12} PM` : hourStr + " AM";
};

const formatMonth = (mon) => {
  const msg = "Bad config, month is required to be less than 13";
  if (typeof mon !== typeof {}) throw Error("Bad argument");
  if (mon.type === ASTERISK) return { ...mon, value: ["*"] };
  const formatted = mon.value
    .sort()
    .map((val) => months.filter((m) => m.id === val)[0].label);
  return { ...mon, value: formatted };
};

const monthHR = (month) => {
  const mon = formatMonth(month);
  return mon.type === ASTERISK ? "Every Month" : `In ` + mon.value.join(", ");
};

const domHR = (dom) => {
  if (dom.type === ASTERISK) return " on every day";
  let res = dom.value.sort().map((d) => {
    switch (d) {
      case "1":
        return "1st";
      case "2":
        return "2nd";
      case "3":
        return "3rd";
      default:
        return d + "th";
    }
  });

  return res.join(", ");
};

const formatDOW = (dow) => {
  if (typeof dow !== typeof {}) throw Error("Bad args");
  const msg = "Bad config, dow is required to be less than 8";
  if (dow.type === ASTERISK) return { ...dow, value: ["*"] };

  const formatted = dow.value
    .sort()
    .map((val) => daysofweek.filter((m) => m.id === val)[0].label);
  return { ...dow, value: formatted };
};

const dowHR = (dows) => {
  let dow = formatDOW(dows);
  return dow.type === ASTERISK ? "" : dow.value.join(", ");
};

const timeHR = (hour, min, timeZone) => {
  return hourHR(hour) + minHR(hour, min, timeZone);
};

const daysHR = (month, dom, dow, hour, min, timeZone) => {
  if (month.type === ASTERISK) {
    if (dom.type === ASTERISK && dow.type === ASTERISK)
      return "Every day" + timeHR(hour, min, timeZone);
    if (dom.type === ASTERISK)
      return "Every " + dowHR(dow) + timeHR(hour, min, timeZone);
    if (dow.type === ASTERISK)
      return "Every month on the " + domHR(dom) + timeHR(hour, min, timeZone);
    return (
      "Every month on the " +
      domHR(dom) +
      " and every " +
      dowHR(dow) +
      timeHR(hour, min, timeZone)
    );
  } else {
    if (dom.type === ASTERISK && dow.type === ASTERISK)
      return monthHR(month) + " every day " + timeHR(hour, min, timeZone);
    if (dom.type === ASTERISK)
      return (
        monthHR(month) + " each " + dowHR(dow) + timeHR(hour, min, timeZone)
      );
    if (dow.type === ASTERISK)
      return monthHR(month) + domHR(dom) + timeHR(hour, min, timeZone);
    return (
      monthHR(month) +
      " on the " +
      domHR(dom) +
      " and every " +
      dowHR(dow) +
      timeHR(hour, min, timeZone)
    );
  }
};

const hourHR = (hour) => {
  let res = hour.value.map((h) => formatHour(h));
  return hour.type === ASTERISK ? "" : " at " + res.join(", ");
};

const minHR = (hour, min, timeZone) => {
  if (min.type === ASTERISK) return " every minute";
  let hrTime = hour.type !== ASTERISK ? " and " : " At ";
  let res = min.value.map((h) => (h == 1 ? h + " minute" : h + " minutes"));
  return hrTime + res.join(", ") + " " + timeZone;
};

export const getHRtime = (select, timeZone = "local time") => {
  const cron = convertToCronSyntax(select)
    .split(" ")
    .map((item) => CronToObject(item));

  const min = cron[0];
  const hour = cron[1];
  const dom = cron[2];
  const month = cron[3];
  const dow = cron[4];

  let hrTime = "";

  try {
    hrTime = daysHR(
      month,
      dom,
      dow,
      hour,
      min,
      timeZone === "default" ? "UTC" : timeZone
    );
  } catch (err) {
    Sentry.configureScope((scope) =>
      scope.setExtra({ select: select }).setLevel(Sentry.Severity.Error)
    );
    console.log("select: ", select);
    Sentry.captureException(err, getHRtime);
  }
  return hrTime;
};
