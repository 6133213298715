import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from '@shopify/app-bridge/actions';
import { Page } from '@shopify/polaris';
import React from "react";

import { TitleBar } from "@shopify/app-bridge-react";
import { BlockStack, Button, Card, FooterHelp, Frame, Layout, Link, List } from "@shopify/polaris";

const basicImg     = "https://priceupdater.wp-dataanalytics.de/img/basic.svg";
const scheduledImg = "https://priceupdater.wp-dataanalytics.de/img/scheduled.svg";

let HOST_URL = "https://priceupdater.wp-dataanalytics.de";


const OfferImage = ({ image }) => (
  <div className="Offer__ImageContainer">
     <img
      src={image}
      role="presentation"
      alt=""
      className="Offer__Image"
    />
      <style jsx>{`
      .Offer__Image {
          position: relative;
          width: 75%;
/*          left: 25%;*/
        }
      `}</style>
  </div>
);

const OfferAction = ({ action }) => (
  <div className="Polaris-EmptyState__Actions">
     <BlockStack>
        <Button  onClick={action.onAction} variant="primary">{action.content}</Button>
     </BlockStack>
  </div>
);

const OfferDetails = (props) => {
  return (
    <div className="Polaris-EmptyState__Details">
      <div className="Polaris-TextContainer">
        <p className="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
          {props.heading}
        </p>
        <OfferImage image={props.image} />
        <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall" style={{}}>
          
          {props.subheading?props.subheading:<span>&nbsp;&nbsp;</span>}
        </p>
        <div className="Polaris-EmptyState__Content">
          {props.children}
          <style jsx>{`
      .Polaris-EmptyState__Content {
          text-align: left;
          left: 35%;
        }
      `}</style>
        </div>
      </div>
      <OfferAction action={props.action} />
    </div>
  );
};

const Offer = (props) =>{
  return (
    <div className="Polaris-EmptyState Polaris-EmptyState--imageContained Polaris-EmptyState--withinContentContainer" 
    style={{height: "448px"}}
       >
      <div className="Polaris-EmptyState__Section">
        <div className="Polaris-EmptyState__DetailsContainer" >
        <OfferDetails {...props} />  
        </div>
        
      </div>
    </div>
  );
}


export default function Plans(props) {
  console.log('props: ',JSON.stringify(props))
  console.log('host: ', HOST_URL)

  const app = useAppBridge();
  const redirect = Redirect.create(app);


  const handleFree = () => {
    const search = new URLSearchParams(window.location.search);
    const host= search.get("host");
    const url = HOST_URL+`/?shop=${props.shop}&host=${host}`
    console.log('handleFree: ',url)
    redirect.dispatch(Redirect.Action.REMOTE, url);
  };

  const handlePaid = () => {
    const search = new URLSearchParams(window.location.search);
    const url = search.get("url");
    console.log('handlePaid: ',url)
    redirect.dispatch(Redirect.Action.REMOTE, url);
  };


  return (
    <Frame>
      <Page>
        <TitleBar title="Update Product Prices from Google Sheet" />

        <Layout>
          <Layout.Section oneHalf>
            <Card style={{ height: "100%" }}>
              <Offer
                heading="Update Prices via Google Sheets"
                action={{
                  content: "Select free plan",
                  onAction: handleFree,
                }}
                imageContained
                image={basicImg}
              >
                <List type="bullet">
                  <List.Item>
                    Use the power of Google Sheets to calculate your prices
                  </List.Item>
                  <List.Item>
                    Save time updating your prices and reduce errors
                  </List.Item>
                  <List.Item>Update prices for up to 125 product variants with the click of a mouse</List.Item>
                
                  <List.Item>free</List.Item>
                </List>
              </Offer>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card>
              <Offer
                heading="Update Prices via Google Sheets"
                subheading="Schedule automatic updates"
                action={{
                  content: "Select paid plan",
                  onAction: handlePaid,
                }}
                imageContained
                image={scheduledImg}
              >
                <List type="bullet">
                  <List.Item>
                    Use the power of Google Sheets to calculate your prices
                  </List.Item>
                  <List.Item>
                    Save time updating your prices and metafields
                  </List.Item>
                  <List.Item>
                    Reduce errors
                  </List.Item>                  
                  <List.Item>
                    <b>Automate regular updates and receive detailed reports</b>
                  </List.Item>
                  <List.Item>10 USD monthly subsricption</List.Item>
                </List>
              </Offer>
            </Card>
          </Layout.Section>
        </Layout>
        <FooterHelp>
          Learn more about the{" "}
          <Link external url="https://priceupdater.wp-dataanalytics.de/">
            WP Price Updater
          </Link>
        </FooterHelp>
      </Page>
    </Frame>
  );};
