import React, {useContext } from "react";
import { WebsocketContext, WSMessage } from "./WebSocketContext";
import { PrettyPrint } from "../WPState";
import { WPStatus } from "../WPState";

const SERVER = import.meta.env.VITE_SERVER

type WPStatesProp = {
  data: WSMessage|null,
  onDismiss: (state: boolean) => void,
  title: string,
}

const ExcelStates = (props: WPStatesProp) => {
  const { data } = props;
  // const onDownload = async (data) => {
  //   await fetch(`https://${SERVER}${data.url}`, {
  //     method: "POST",
  //     body: data.data,
  //   })
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = "variants.xlsx";
  //       link.click();

  //       window.URL.revokeObjectURL(link.href);
  //       props.onDismiss(false);
  //     });
  // };

  if (!data || Object.keys(data).length === 0) {
    return (
      <WPStatus {...props}>
        <p>Starting to export...</p>
      </WPStatus>
    );
  }

  if (data?.status === "ERROR") {
    return (
      <WPStatus
        {...props}
        status="critical"
      >
        <p>{data?.error}</p>
      </WPStatus>
    );
  }

  if (data?.status === "RUNNING") {
    return (
      <WPStatus
        {...props}
        status="info"
      >
        <p>{data?.msg}</p>
      </WPStatus>
    );
  }

  // if (data?.status === "COMPLETED") {
  //   let bulk = JSON.parse(data?.data||"{}")
  //   return (
  //     <WPStatus
  //       {...props}
  //       status="success"
  //       action={{content: `Click to download ${bulk?.objectCount|| 0} product variants`, onAction: ()=>onDownload(data)}}
  //     >
  //     </WPStatus>
  //   );
  // }

  return (
    <WPStatus {...props}>
      <PrettyPrint jsonObj={data} />
    </WPStatus>
  );
};



const XLSTemplate = (props) =>{
  const { onCancel } = props;
  const [ready, val, send] = useContext(WebsocketContext); 
  const title = "Exporting product variants to a Excel sheet ";
  return <ExcelStates data={val} onDismiss={() => onCancel(false)} title={title} />
}

export default ExcelStates;
