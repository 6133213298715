import React, { useContext, useCallback, useState, useEffect } from "react";

import { GoogleContext } from "../../contexts/GoogleContext";
import { TextField, Banner, BlockStack, Button, ButtonGroup, InlineStack, Spinner } from "@shopify/polaris";
import { ShopContext } from "../../contexts/ShopContext";
import { getSchedule, postSchedule } from "../../contexts/ScheduleContext";
import Guide from "../cron/Guide";
import { getHRtime, convertToCronSyntax } from "../cron/utils/helper";

import moment from "moment";

import cronstrue from "cronstrue/i18n";

export const initialSchedule = {
  min: "*",
  hour: "*",
  dom: "*",
  mon: "*",
  dow: "*",
};

const SERVER = import.meta.env.VITE_SERVER;

export const isInitialSchedule = (select) => {
  if (!select) return true;
  let keys = Object.keys(select);
  return Object.keys(select).every((k) => select[k] === initialSchedule[k]);
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const SchedulerError = ({ error }) => (
  <Banner
    title={error?.title}
    status={error?.status}
    secondary={{ content: "Learn more", url: "" }}
  >
    <p>{error?.message}</p>
  </Banner>
);


const EmailField = React.forwardRef((props, ref) => (
  <TextField type="email" autoComplete="email" ref={ref} {...props} />
));

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : "en";


const useSchedule = (init) => {
      if (isInitialSchedule(init)) {
        init.hour = moment().hour();
        init.min = moment().minute();
      }
      const [select, setSelect_] = useState(init);
      const setSelect = (value) => {
        if (value.hour === "*" && value.min === "*") {
          value.hour = moment().hour();
          value.min = moment().minute();
        }
        setSelect_(value);
      };
      return [select, setSelect];
    };    


// const useSchedule = (init) => {
//   // Create a deep copy of init to avoid mutating the original object
//   const initialSchedule = { ...init };

//   // Initialize the state
//   const [select, setSelect_] = useState(() => {
//     if (isInitialSchedule(initialSchedule)) {
//       return {
//         ...initialSchedule,
//         hour: moment().hour(),
//         min: moment().minute(),
//       };
//     }
//     return initialSchedule;
//   });


//   useEffect(() => {
//     if (isInitialSchedule(init)) {
//       setSelect_({
//         ...select,
//         hour: moment().hour(),
//         min: moment().minute(),
//       });
//     }
//   }, [init, select]);

//   const setSelect = (value) => {
//     if (value.hour === "*" && value.min === "*") {
//       value.hour = moment().hour();
//       value.min = moment().minute();
//     }
//     setSelect_(value);
//   };

//   return [select, setSelect];
// };

export default function Scheduler({
  // open,
  // onClose,
  // googleUrl
}) {
  const { shopName, shopData } = useContext(ShopContext);
  const [isLoading, setIsLoading] = useState(true);
  // const { schedule, setSchedule } = useContext(ScheduleContext);
  // const [select, setSelect,modified,setModified] = useSchedule(initialSchedule);
  const [select, setSelect] = useSchedule(initialSchedule);
  const [email, setEmail] = useState(shopData?.email || "");
  const [state, setState] = useState("");
  const [paused, setPaused] = useState(false);
  const [error, setError] = useState(null);
  const [modified,setModified] = useState(false);


  //return <div>hier</div>
  const { isLoading:google_loading,  google } = useContext(GoogleContext);

  const emailRef = React.useRef();

  // return <div>hier</div>

  useEffect(() => {
    const fetchSchedule = async () => {
      console.log('HIER')
      getSchedule(shopName)
        .then((res) => {
          setSelect(res.schedule)
          setModified(false)
          if (res.email) {
            setEmail(res.email)
          }
          setPaused(res.paused)
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err)
          setError(err);
        })
    };
    fetchSchedule()
  }, [])

  //console.log(select,email,shopData?.email) 

  const submitRequest = async () => {
    let value = convertToCronSyntax(select);
    let date = new Date();
    let data = {
      shop: shopName,
      email: email,
      url: google.url,
      schedule: select,
      CRON: value,
      paused: paused,
      Message: cronstrue.toString(value, { locale: getNavigatorLanguage() }),
      localeTime: date.toLocaleTimeString(),
      TimeZoneOffset: date.getTimezoneOffset(),
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const res = await postSchedule(shopName, data);
    console.log(res)
    return res
  };
  const onSubmit = () => {
    if (validateEmail(email)) {
      setError(null);
      let res = submitRequest();
      setModified(false)
      // onClose();
    } else {
      setError({
        title: "Missing input",
        status: "critical",
        message: "Email address is missing or invalid. Please check below.",
      });
    }
  };

  const onClear = () => {
    setSelect(initialSchedule);
  };

  const handleEmailChange = useCallback((value) => setEmail(value), []);

  let isValidEmail = false;
  let errorMessage = ""
  useEffect(() => {
    isValidEmail = validateEmail(email)
    errorMessage = isValidEmail ? "" : "Please enter valid email address. ";
  }, [email]);

  if (isLoading) {
    return <Spinner />
  }

  return (
    <BlockStack gap="500">
      <Guide
        select={select}
        setSelect={setSelect}
        setModified={setModified}
        // onClear={onClear}
        // setState={setState}
        // setCRON={setCRON}
        paused={paused}
        setPaused={setPaused}
      />
      <EmailField
        value={email}
        onChange={handleEmailChange}
        label="Email"
        type="email"
        helpText={
          <span>
            This email address is soley used for the reports on the scheduled
            price updates.
          </span>
        }
        error={errorMessage}
        ref={emailRef}
      />
      <InlineStack align="end" direction="row-reverse">
        <ButtonGroup>
          <Button
            onClick={ onClear }
            accessibilityLabel="Clear schedule"
            variant="primary"
          >
            Clear schedule
          </Button>

          <Button
            onClick={onSubmit}
            accessibilityLabel="Submit schedule request"
            variant="primary"
            disabled={!modified}
          >
            Submit schedule request
          </Button>
        </ButtonGroup>
      </InlineStack>
    </BlockStack>
  );
}

Scheduler.propTypes = {
  // open: PropTypes.bool,
  // onClose: PropTypes.func,
  // googleUrl: PropTypes.string,
};

export { getHRtime };
