import React, { createContext, useEffect, useState } from "react";

import { /* ShopDataDefault,*/ ShopDataType } from "../types";


const SERVER = import.meta.env.VITE_SERVER;




export type ShopContextProps = {
  isLoading: boolean,
  shopName: string,
  host: string,
  shopData:{},
  appInstallation:{},
  isActive: boolean,
  setActive: (data: boolean) => void,
  subscriptions: [],
  error: null,
};  


export const ShopContext = createContext<ShopContextProps>({
  isLoading: false,
  shopName: "",
  host: "",
  shopData: {},
  appInstallation:{},
  isActive: false,
  setActive: ()=>{},
  subscriptions:[],
  error: null,});

type ShopContextProviderProps = {
  // shopName: string,
  // host: string,
  children: React.ReactNode
}

export const ShopContextProvider=({children}: ShopContextProviderProps )=>{
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shopData, _setShopData] = useState<{}>({});
  const [isActive, setActive] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<[]>([]);
  const [appInstallation,setAppInstallation]= useState({})
  const [error, setError] = useState(null);
 // const [shop_]

  const shopName = shopify.config.shop;
  const host = shopify.config.host||'';
  useEffect(()=>{
    if (shopName){
      fetch(`https://${SERVER}/${shopName}`)
      .then((res) => res.json())
      .then((res)=>{
        _setShopData(res?.shop) 
        setActive(res?.appInstallation?.activeSubscriptions.length>0)
        setIsLoading(false);
        setSubscriptions(res?.appInstallation?.activeSubscriptions)
        setAppInstallation(res?.appInstallation)
      })
      .catch((err)=>{
        console.error(err)
        setError(err)
      })
    }},[shopName])
  return (
    <ShopContext.Provider value={{isLoading,shopName,host,shopData,appInstallation,isActive,setActive,subscriptions,error}}>
      {children}
    </ShopContext.Provider>
  );
}

//export default ShopContext;

