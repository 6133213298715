import {
  Banner,
  Button,
  LegacyCard as Card,
  List
} from "@shopify/polaris";
import React from "react";
import WPStates from "./WPState";
import { Exports } from "./exports";

const GetStarted = ({setupActive,setExport}) => (<WPStates
    heading="Get started by setting up a Google Sheet:"
    action={{ content: 'Start exporting', onAction: () => {{setExport(Exports.Google)} }}}
    success={{action: { content: 'Close', onAction: () => { } }                                   
  }}
  >
    <div className="wp-get-started">
    <Card>
    <Banner title="There are two ways to set up your Google Sheet:" tone="info">

    <List type="bullet">
            <List.Item><Button  onClick={()=>{setupActive(true)}} variant="plain">By providing a link to a Google Sheet.</Button></List.Item>
            <List.Item><Button  onClick={()=>{setExport(Exports.Google)}} variant="plain">If you have a Google Drive, just start exporting to create a first Google Sheet.</Button></List.Item>
           {/* <List.Item><Button plain onClick={()=> {setExport(Exports.Google)}}>Or start exporting and create a Google Sheet on our Google Drive.</Button></List.Item> */}
    </List>
   </Banner> 
   <Card.Section>
  <p>Once you have exported your product variants to a Google Sheet,<br/> you can update your product prices from the Google Sheet.</p>
  </Card.Section> 
  <Card.Section>
  <p>To setup a Google Drive visit <a href="https://www.google.com">www.google.com</a>.</p>
  </Card.Section> 

  </Card>  
  </div>
  </WPStates>)

export default GetStarted
