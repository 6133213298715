import React, { useState, useEffect, createContext, useRef } from "react";


const SERVER = import.meta.env.VITE_SERVER;
const WP_ACCESS_TOKEN = import.meta.env.VITE_WP_ACCESS_TOKEN

export type WSMessage = {
  status: string;
  msg: string;
  error: string;
  data: string|null;
  url: string|null;
}

export const WebsocketContext = createContext<[boolean,WSMessage|null,object]|any>([false, null, () => {}]);
//                                            ready, value, send

// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.
export const WebsocketProvider = ({ children,shop,query,url=null }) => {
  const [isReady, setIsReady] = useState(false);
  const [message, setMessage] = useState(null);

  const ws = useRef<WebSocket|null>(null);

  useEffect(() => {
    const socket = new WebSocket(`wss://${SERVER}/ws/${shop}?q=${query}&authorization=${WP_ACCESS_TOKEN}`);

    socket.onopen = () => {
      console.log("Socket opened")
      socket.send(JSON.stringify({ shop: shop, task: query,url:url }));
      setIsReady(true);
    }
    socket.onclose = () => {
      console.log("Socket closed")
      setIsReady(false);
    }
    socket.onmessage = (event) =>{ 
      console.log('websocket: ',event.data)
      setMessage(JSON.parse(event.data))
    };

    ws.current = socket;

    return () => {
      socket.close();
    };
  }, []);

  const ret = [isReady, message, ws.current?.send.bind(ws.current)];

  return (
    <WebsocketContext.Provider value={ret}>
      {children}
    </WebsocketContext.Provider>
  );
};