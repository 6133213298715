import React, { useCallback, useContext, useState } from "react";

import { Page,Card } from "@shopify/polaris"

// import {
//   SettingsIcon
// } from '@shopify/polaris-icons';
import WPFrame from "../components/WPFrame";
import { ExportProducts } from "../components/exports";

// import { TitleBar } from "@shopify/app-bridge-react";

// import { LoadingPage } from "../components/LoadingPage";

import GetStarted from "../components/GetStarted";
import { Exports } from "../components/exports";

import { ShopContext } from "../contexts/ShopContext";

// import {WPFooterHelp, LEARNMORE} from "../components/WPFrame.tsx"
import Plan from "../components/Subscription/Plan";



import './index.css';




export default function Index() {

  const emptyToastProps = { content: null };
  const [setupActive, setSetupActive] = useState<boolean>(false);

  const [exportVariants, setExport] = useState<any|null>(null);
  const [toastProps, setToastProps] = useState(emptyToastProps);

  const [openPlan, setOpenPlan] = useState<boolean>(false);


  const toastMarkup = toastProps.content && (
    shopify.toast.show(toastProps.content, {
      onDismiss: () => {setToastProps(emptyToastProps)}, // Dismiss logic
    }));
  //   <Toast {...toastProps} onDismiss={() => setToastProps(emptyToastProps)} />
  // );
  
  const { shopName } = useContext(ShopContext);

 
  const handleExport = useCallback(
      (val) => setExport(val),
      []
    );
 

    
  
  
  // if (isLoading) return <LoadingPage setOpenPlan={setOpenPlan}/>

  const actionGroups = [{
   title: 'Export products', 
   actions: [{ content: '... to Google sheet', onAction: () => { setExport(Exports.Google) } },
     { content: '... to Excel sheet', onAction: () => { setExport(Exports.Excel) } }
     ]
   }
  ];
  
  const Inner = () => {
    if (exportVariants !== null) {
      return <ExportProducts shop={shopName}
             exportType={exportVariants}
             setExport={handleExport}
      />
    }
    return <GetStarted 
               setupActive={setSetupActive} 
               setExport={setExport} 
            />
  }

  
 // @ts-ignore
 
 if (openPlan) {
  return (
    <Plan onClose={() => setOpenPlan(false)}  /> 
  )
 }
  return (
    <WPFrame 
       // shopData={shopData} 
       //isloading={isLoading} 
       // logo={logo} 
       toastMarkup={toastMarkup}
       //primaryAction={primaryAction}
       actionGroups={actionGroups}
       setOpenPlan={setOpenPlan}>
      <Inner />
    </WPFrame>
  );

}
