import React, { useContext, useState, useEffect, useCallback } from "react";

import { Page, Box, Link } from "@shopify/polaris"
import { TitleBar } from "@shopify/app-bridge-react";

import { ShopContext } from "../contexts/ShopContext";
import { WPFooterHelp, LEARNMORE } from "../components/WPFrame.tsx"

import {
  IndexTable,
  LegacyCard,
  EmptySearchResult,
  Text,
  Icon,
} from '@shopify/polaris';

import {
  ExternalIcon
} from '@shopify/polaris-icons';

const REPORTS = import.meta.env.VITE_REPORTS;

const formatDateTime = (datetimeString) => {
  // Parse the datetime string
  const year = datetimeString.slice(0, 4);
  const month = datetimeString.slice(4, 6);
  const day = datetimeString.slice(6, 8);
  const hour = datetimeString.slice(9, 11);
  const minute = datetimeString.slice(11, 13);
  const second = datetimeString.slice(13, 15);

  // Create a Date object
  const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`);

  // Format the date
  return date.toLocaleDateString('en-US');
};

const RecentRuns = () => {
  const [runs, setRuns] = useState([]);
  const shop = shopify.config.shop

  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const response = await fetch(`${REPORTS}/${shop}/updates.json`, {
          headers: { "Content-Type": "application/json" },
        });
        const data = await response.json();
        setRuns(data);
      } catch (err) {
        console.error(err);
        throw err;
      }
    };
    fetchRuns();
  }, [shop]);

  const resourceName = {
    singular: 'run',
    plural: 'runs',
  };
  const date_options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

  if (runs.length < 1) {
    return <EmptySearchResult
      title={'No runs yet'}
      // description={'Try changing the filters or search term'}
      withIllustration
    />

  }

  const rowMarkup = runs.map(
    (
      { errors, update_time, count },
      index,
    ) => {
      let url = `${REPORTS}/${shop}/updates/details/${update_time}`
      let date = new Date(update_time);
      return <IndexTable.Row id={index.toString()} key={index} position={index}>
        <IndexTable.Cell><Link
          dataPrimaryLink
          target="_blank"
          removeUnderline 
          url={url}
          >
          {formatDateTime(update_time)}
        </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" alignment="end" numeric>
            {count}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" alignment="end" numeric>
            {errors}
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell><Link
          target="_blank"
          removeUnderline 
          url={url}
          >
          <Icon
          source={ExternalIcon}
          tone="base"
            />
            </Link>
        </IndexTable.Cell>
      </IndexTable.Row>
    }
  );
  return (
    <LegacyCard>
      <IndexTable
        // condensed={useBreakpoints().smDown}
        resourceName={resourceName}
        itemCount={runs.length}
        headings={[
          { title: 'Update time' },
          { title: 'Count', alignment: 'end' },
          { title: 'Errors', alignment: 'end' },
          { title: 'Details' },
        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

}

const WPTitleBar = (props) => {
  const { isActive } = useContext(ShopContext);
  const host: string = shopify?.config?.host || ''
  const subscription: string = "/subscription?host=" + host
  return <TitleBar
    title={props.title}>
    {!(isActive) &&
      <a href={subscription}>Upgrade</a>
    }
    <section label="?">
      <a href={LEARNMORE} target="_blank">Learn more ...</a>
      <a href='mailto:support@wp-dataanalytics.de?subject=WP%20Price%20Updater' target="_blank">Contact support ...</a>
      <a href='mailto:support@wp-dataanalytics.de?subject=WP%20Price%20Updater:%20Feature%20request' target="_blank">Ask for feature ...</a>
    </section>

  </TitleBar>
}


type WPFrameProp = {
  children: any,
  title?: string,
  toastMarkup?: null | string,
  actionGroups?: Array<any>,
  primaryAction?: any,
  setOpenPlan?: (state: boolean) => void
}


const WPFrame = (props: WPFrameProp) => {
  const { children, toastMarkup, actionGroups, primaryAction } = props;

  return (
    <Page
      fullWidth
      primaryAction={primaryAction}
      actionGroups={actionGroups}
    >
      <WPTitleBar {...props} />
      <Box id="boxMF">
        {children}
      </Box>
      {toastMarkup}
      <WPFooterHelp />
    </Page>

  )
}



export default function Index() {
  return (
    <WPFrame title="Recent Runs">
      <RecentRuns />
    </WPFrame>
  );
}
