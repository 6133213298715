// import { GoogleOAuthProvider } from '@react-oauth/google';
import { NavMenu } from '@shopify/app-bridge-react';
import React,{useContext} from 'react';
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes.jsx";
import {
  AppBridgeProvider,
  PolarisProvider,
  QueryProvider,
} from "./components/index.js";
import { GoogleContextProvider } from "./contexts/GoogleContext.tsx";
import { ShopContextProvider } from "./contexts/ShopContext.tsx";
import { SubscriptionProvider } from "./contexts/SubscriptionStatus.tsx";
import { ShopContext } from "./contexts/ShopContext";


const NavigtionMenu = () => {
  const { isActive } = useContext(ShopContext);
  return (
    <NavMenu>
      <a href="/" rel="home">
        Home
      </a>
      <a href="/activity">Recent runs</a>
      <a href="/google-setup">Google Sheet Setup</a>
      <a href="/metafields-settings">Metafields Setup</a>
      <a href="/scheduling">Scheduling</a>
      {/* <a href="/subscription">Manage your subscription</a> */}
      {/* <a href="/get-started">Get started</a> */}
    </NavMenu>
  )
}



// import { ScheduleContextProvider } from "./contexts/ScheduleContext.tsx";
const Google_client_Id = "646571538880-fs87au3mbtq169pebguhvqb62ve1dtt3.apps.googleusercontent.com"
export default function App() {
  // Any .tsx or .jsx files in /pages will become a route
  // See documentation for <Routes /> for more info
  const pages = import.meta.glob("./pages/**/!(*.test.[jt]sx)*.([jt]sx)", { eager: true });

 return (
    <PolarisProvider>
      <BrowserRouter>
        <AppBridgeProvider>
          <QueryProvider>
            <ShopContextProvider>
              <SubscriptionProvider>
                {/* <GoogleOAuthProvider clientId={Google_client_Id}> */}
                  <GoogleContextProvider>
                    <Routes pages={pages} />
                    <NavigtionMenu/>
                   </GoogleContextProvider>
                {/* </GoogleOAuthProvider> */}
              </SubscriptionProvider>
            </ShopContextProvider>
          </QueryProvider>
        </AppBridgeProvider>
      </BrowserRouter>
    </PolarisProvider>
  );
}
