import { Banner, EmptyState } from "@shopify/polaris";
import React from "react";

export function PrettyPrint(props) {
  return <pre>{JSON.stringify(props.jsonObj, null, 2)}</pre>;
}
// sftp://access768796896.webspace-data.io/priceupdater/img/overview_small.svg
const basicImg = "https://priceupdater.wp-dataanalytics.de/img/overview_small.svg";


export const WPStatus = (props) => {
   return (
     /* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */
     <Banner {...props}>
       {props.children}
     </Banner>
   );
}

const LEARNMORE = import.meta.env.VITE_LEARN_MORE;

export const learnMoreAction={
     content: 'Learn more',
     url: LEARNMORE,
}


const WPState = (props) =>

  <EmptyState
    heading={"Manage your product (variant) prices"}
    secondaryAction={learnMoreAction}
    {...props}
    image={basicImg}
    largeImage={basicImg}
    fullWidth
    imageContained>
    {props.children}
  </EmptyState>


export default WPState