import { TitleBar } from "@shopify/app-bridge-react";
import { Card, FooterHelp, Link, Page, Thumbnail } from "@shopify/polaris";

import React, { useContext } from "react";
import { GoogleContext } from "../contexts/GoogleContext";
import { ShopContext } from "../contexts/ShopContext";

export const LEARNMORE = import.meta.env.VITE_LEARN_MORE;

export const WPFooterHelp = () => (<FooterHelp>
  Learn more about the{" "}
  <Link
    target='_blank'
    url={LEARNMORE}
  >
    WP Price Updater
  </Link>
</FooterHelp>)

export const WPTitleBar = ({ setOpenPlan }) => {
  const handleChangeSubscription = () => {
    setOpenPlan(true)
  }

  const { isActive } = useContext(ShopContext);

  return <TitleBar
    title="Update Product Prices from Google Sheets">
      {!(isActive) && 
        (<button onClick={handleChangeSubscription}>
          Upgrade
          </button>)
        }
    <section label="?">
    <button onClick={handleChangeSubscription}>
          Manage subscription
          </button>
      <a href={LEARNMORE} target="_blank">Learn more ...</a>
      <a href='mailto:support@wp-dataanalytics.de?subject=WP%20Price%20Updater' target="_blank">Contact support ...</a>
      <a href='mailto:support@wp-dataanalytics.de?subject=WP%20Price%20Updater:%20Feature%20request' target="_blank">Ask for feature ...</a>
    </section>

  </TitleBar>
}

type WPFrameProp = {
  children: any,
  toastMarkup?: null|string,
  actionGroups?: Array<any>,
  primaryAction?: any,
  title?: null|string,
  setOpenPlan?: (state: boolean) => void
}


const WPFrame = (props:WPFrameProp) => {
  const { children, toastMarkup, actionGroups, primaryAction } = props;
  const { google } = useContext(GoogleContext)
  const google_thumpnail = google.thumpnail || "/GoogleSheet.svg"

  return (
    <Page
      //title="Update Product Prices from Google Sheets"
      titleMetadata={
      <Link url={google.url||''} target="_blank">
        <Thumbnail
          source={google_thumpnail}
          alt="Open GoogleSheet"
          size="small"
        />
      </Link>}
      primaryAction={primaryAction}
      actionGroups={actionGroups}
    >

      <WPTitleBar {...props} />
      <Card>
        {children}
      </Card>
      {toastMarkup}
      <WPFooterHelp />
    </Page>

  )
}

export default WPFrame;