import React, { useContext, useState } from "react";
//import { Banner, Link, Card } from "@shopify/polaris";

// import { GoogleContext } from "../contexts/GoogleContext";
import { WebsocketContext, WebsocketProvider, WSMessage } from "./exports/WebSocketContext";
import WPStates, { PrettyPrint, WPStatus } from "./WPState";

type WPStatesProp = {
  data: WSMessage|null,
  onDismiss: (state: boolean) => void,
  title: string,
  setUpdateData: (props: any) => void,
  props: any
}


const _WPStates = (props: WPStatesProp) => {
  const { data,onDismiss } = props;
  if (!data || Object.keys(data).length === 0) {
    return (
      <WPStatus {...props}>
        <p>Starting to export...</p>
      </WPStatus>
    );
  }

  if (data?.status === "ERROR") {
  
    return (
      <WPStatus
        {...props}
        title = {`Error updating the prices of the product variants`}
        status="critical"
      >
        <p>{data?.msg}</p>
      </WPStatus>
    );
  }

  if (data?.status === "RUNNING") {
    return (
      <WPStatus
        {...props}
        status="info"
      >
        <p>{data?.msg}</p>
      </WPStatus>
    );
  }

  if (data?.status === "COMPLETED") {
    // const onclose=()=>{
    //   setGoogle(data)
    // }
    props?.setUpdateData(data)
    return (
      <WPStatus
        {...props}
        title={data.msg}
        status="success"
        //action={{content: `Close`, onAction:()=> {onDismiss(false)}}}
        //secondaryAction={{content: `Click here for details`, url: data_.url, external: true}}
      >
      </WPStatus>
    );
  }

  return (
    <WPStatus {...props}>
      <PrettyPrint jsonObj={data} />
    </WPStatus>
  );
};


const UpdateFinished = (props) => {
  return <WPStatus
      {...props}
      title={props.data.msg}
      status="success"
      // action={{content: `Close`, onAction:()=> {onDismiss(false)}}}
      // secondaryAction={{content: `Click here for details`, url: data.url, external: true}}
    >

    </WPStatus>
   }

//   if (data?.errors?.length === 0) {
//     return (
//     <Banner status={"success"}>
//       <p>The prices of {data?.variants} product variants were updated successfully.</p>
//     </Banner>)
//   }
//   if (data?.errors.length > 0) {
//   return (
//      <Banner     status={"warning"}>
//        <p>
//           Tried to update {data?.variants} product variants, but there were{" "}
//           <b> {data?.errors.length} errors </b>
//         </p>
//       </Banner>
//        )}

//   return (
//     <Banner status={"critical"}>
//       <p>There was an unkonwn error when trying to update the variant prices. Please try again. If this error persists please contact support.</p>
//     </Banner>)
// };

const UpdateProgress = (props) => {
  const { onCancel } = props;


  const [ready,message,send] = useContext(WebsocketContext);
  let title = "Updating the prices of the product variants";
  return (
    <_WPStates data={message} onDismiss={() => onCancel(false)} title={title} {...props}/>
  );
};

// const UpdateProgress = (props) => {
//   return (
//   <WebsocketProvider shop={props.shop} query="updatePrices" url={props.url}>
//     <_UpdateProgress {...props}/>
//   </WebsocketProvider>
//   );
// };


export const UpdatePrices = (props) => {
  const { shop, google, setUpdate } = props;
  const [updateData, setUpdateData] = useState({});


  if (updateData?.status === 'COMPLETED') {
    //let title = `Updated the prices of ${msg?.variants} product (variants)`
    let title = `Price update completed`;
    let data = JSON.parse(updateData?.data)
    return <WPStates
      heading={title}
      action={{
        content: 'Close', onAction: () => {
          setUpdate(false);
        }
      }}
      secondaryAction={{
        content: 'View details',
        url: data?.url
      }}
    >
      <UpdateFinished data={updateData} {...props} />
    </WPStates>
  }
  return <WPStates
    heading="Updating your product prices from a Google Sheet"
    action={{
      content: 'Cancel', onAction: () => {
        setUpdate(false);
      }
    }}
  >
    <WebsocketProvider shop={props.shop} query="updatePrices" url={google.url}>
      <UpdateProgress shop={shop} url={google?.url} setUpdateData={setUpdateData} onClose={setUpdate} onCancel={setUpdate}/>
    </WebsocketProvider>
  </WPStates>
}

export default UpdatePrices;