import React from 'react'
import PropTypes from 'prop-types'
import { SelectFields,DropDownWrapper} from '../components/DropDown'

import TimePicker from '../components/timepicker';
import moment from 'moment';

import daysofmonth from './daysofmonth.json' // assert {type: 'json'}
//const daysofmonth = require('./daysofmonth.json');
import daysofweek from './daysofweek.json' // assert {type: 'json'}
//const daysofweek = require('./daysofweek.json');
import months from './month.json' // assert {type: 'json'}
//const months = require('./month.json');


const DropDownsWrapper = (props) => (
  <form>
    <div className="dropdowns__wrapper">{props.children}</div>
  </form>
);

DropDownsWrapper.propTypes={
  children: PropTypes.node.isRequired
}


const DropdownForm = ({
  handleClear,
  handleClickClose,
  handleChange,
  handleTimeChange,
  select
}) => {

  let scheduletime = moment()
               .minutes(select.min)
               .hour(select.hour)
  return (
    <>
      <DropDownsWrapper>
        <SelectFields
          onClear={handleClear}
          onClose={handleClickClose}
          selected={select.mon.split(",")}
          onChange={handleChange}
          data={months}
          title="IN"
          placeholder="Every Month"
          fieldname="mon"
        />
        <SelectFields
          onClear={handleClear}
          onClose={handleClickClose}
          selected={select.dom.split(",")}
          onChange={handleChange}
          data={daysofmonth}
          title="ON"
          placeholder="Every Day of Month"
          fieldname="dom"
          picker
        />
        <SelectFields
          onClear={handleClear}
          onClose={handleClickClose}
          selected={select.dow.split(",")}
          onChange={handleChange}
          data={daysofweek}
          title="ON"
          placeholder="Every Day of Week"
          fieldname="dow"
        />
        <DropDownWrapper title="AT" active >
          <TimePicker
            style={{ marginTop: 10 }}
            onChange={handleTimeChange}
            showSecond={false}
            defaultOpen={false}
            defaultValue={scheduletime}
            //focusOnOpen
            allowEmpty={false}
          />
        </DropDownWrapper>
      </DropDownsWrapper>
    </>
  );
}

DropdownForm.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleClickClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  select: PropTypes.shape({
    min: PropTypes.string,
    hour: PropTypes.string,
    dom: PropTypes.string,
    mon: PropTypes.string,
    dow: PropTypes.string
  }).isRequired,
}

export default DropdownForm
