import React,{useContext,useState} from "react";
import { Frame,Modal, Toast,  List } from "@shopify/polaris";
import { Redirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
// @ts-ignore
import { ShopContext } from "../../contexts/ShopContext";
import { createSubscription,cancelSubscription } from "../../contexts/SubscriptionStatus";



const basicImg     = "https://priceupdater.wp-dataanalytics.de/img/basic.svg";
const scheduledImg = "https://priceupdater.wp-dataanalytics.de/img/scheduled.svg";


const OfferImage = ({ image }) => (
  <div className="Offer__ImageContainer">
     <img
      src={image}
      role="presentation"
      alt=""
      className="Offer__Image"
    />
      <style>{`
      .Offer__Image {
          position: relative;
          width: 75%;
        }
      `}</style>
  </div>
);

const OfferDetails = (props) => {
  return (
    <div className="Polaris-EmptyState__Details">
      <div className="Polaris-TextContainer">
        <p className="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
          {props.heading}
        </p>
        <OfferImage image={props.image} />
        <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall" style={{}}>
          
          {props.subheading?props.subheading:<span>&nbsp;&nbsp;</span>}
        </p>
        <div className="Polaris-EmptyState__Content">
        <List type="bullet">
            <List.Item>
              Use the power of Google Sheets to calculate your prices
            </List.Item>
            <List.Item>
              Save time updating your prices and reduce errors
            </List.Item>
            <List.Item>
              <b>Automate regular updates and receive detailed reports</b>
            </List.Item>
            <List.Item>10 USD monthly subsricption</List.Item>
          </List>
          <style jsx>{`
      .Polaris-EmptyState__Content {
          text-align: left;
          left: 35%;
        }
      `}</style>
        </div>
      </div>
    </div>
  );
};

const Offer = (props) =>{
  return (
    <div className="Polaris-EmptyState Polaris-EmptyState--imageContained Polaris-EmptyState--withinContentContainer" 
       >
      <div className="Polaris-EmptyState__Section">
        <div className="Polaris-EmptyState__DetailsContainer" >
        <OfferDetails {...props} />  
        </div>      
      </div>
    </div>
  );
}


const PaidVersion = () =>(
  <Offer
  subheading="Schedule automatic updates"
  imageContained
  image={scheduledImg}
>
  <List type="bullet">
    <List.Item>
      Use the power of Google Sheets to calculate your prices
    </List.Item>
    <List.Item>
      Save time updating your prices and reduce errors
    </List.Item>
    <List.Item>
      <b>Automate regular updates and receive detailed reports</b>
    </List.Item>
    <List.Item>10 USD monthly subsricption</List.Item>
  </List>
</Offer>
)

//type appSubscriptionCreateProp = ExecutionResult<{}>,

const Upgrade = ({ onClose}) => {
  const app = useAppBridge();  
  const { host,shopName } = useContext(ShopContext);

  const handleSubscribe = async() => {
    try {
      let confirmationUrl = await createSubscription(shopName,host);
      if (confirmationUrl) {
        // history.pushState(null,'',confirmationUrl)
        // navigation.navigate('/settings', {
        //   history: 'push',
        // });
        // navigate(confirmationUrl)
        window.open(confirmationUrl,'popup','width=600,height=600')
        // window.location.href = confirmationUrl
        // const redirect = Redirect.create(app);
        // redirect.dispatch(Redirect.Action.REMOTE, confirmationUrl);       
     } 
    } catch (error) {
      console.log(error);
    } 
  }

  
  return (
    <Modal
      open={true}
      onClose={onClose}
      title="Automate the Updating of Prices via Google Sheets"
      primaryAction={{
        content: "Start subscription",
        onAction: handleSubscribe,

      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
      <PaidVersion />
      </Modal.Section>
    </Modal>
  )
}

const Downgrade = ({ onClose }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const { shopName, subscriptions,setActive } = useContext(ShopContext);
 
  const handleCancelSubscription = async () => {
    try {
      if (subscriptions.length===0) {
        return;
      }
      let data = await cancelSubscription(shopName,subscriptions[0].id);
      if (data!==null) {
        setSuccess(true);
        setActive(false);
      }
    } catch (error) {
      console.log(error);
    }
    // let data = await setSubscription(false)
    // if (data!==null) {
    //   setSuccess(true);
    // }
  }
  const _onClose = () => {
    // setActive(false);
    onClose()
  }
  const toastMarkup =
    success ? <Toast
      content={`Subscription to paid version is cancelled.`}
      onDismiss={_onClose}
      duration={4500}
    /> : null;


  return (
    <Modal
      open={true}
      onClose={onClose}
      title="Automate the Updating of Prices via Google Sheets"
      primaryAction={{
        content: "Cancel subscription",
        onAction: handleCancelSubscription,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        {toastMarkup}
        <PaidVersion />
      </Modal.Section>
    </Modal>)
}


type PlanProps ={
  onClose: React.Dispatch<React.SetStateAction<boolean>>,

}

export default function Plan({onClose}:PlanProps) { 
  const {isActive } = useContext(ShopContext);
  const content = !isActive?
        <Upgrade  onClose={onClose}/>
        :<Downgrade  onClose={onClose} />
  

  return (
    <div style={{ height: "500px" }}>
      <Frame>
      {content}
      </Frame>
    </div>
  );
}

