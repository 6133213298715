import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";

// import styles from "../Guide.module.css";
import { SelectedField } from "./SelectedField";

const itemProp = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});


export const DropDownWrapper = (props) => {
  const { active, title } = props;
  return (
    <div className="dropdown__container">
      <h1 className={active ? " selected" : ""}>{title}</h1>
      <div className="dropdown__wrapper">
        <div className="dropdown">{props.children}</div>
      </div>
    </div>
  );
};

DropDownWrapper.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
};

export const DropDownLine = ({ item, onChange, isOn, key }) => {
  return (
    <li onClick={() => onChange(item)} key={key}>
      <div className="label">{item.label}</div>
      <input value={item.label} readOnly className={isOn ? "selected" : ""} />
      <Checkbox id={item.id} isOn={isOn} />
    </li>
  );
};

DropDownLine.propTypes = {
  item: itemProp,
  onChange: PropTypes.func,
  isOn: PropTypes.bool,
  key: PropTypes.string,
};

export const DropDownList = ({ data, onChange, selected }) => (
  <ul className="dropdown__list">
    {data.map((item) => (
      <DropDownLine
        item={item}
        key={item.id}
        onChange={onChange}
        isOn={selected.includes(item.value)}
      />
    ))}
  </ul>
);

DropDownList.propTypes = {
  data: PropTypes.arrayOf(itemProp),
  onChange: PropTypes.func,
  selected: PropTypes.object,
};

const PickerWrapper = (props) => <div className="picker">{props.children}</div>;

PickerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const PickerCell = ({ item, onChange, isOn, key }) => {
  return (
    <li onClick={() => onChange(item)} key={key} aria-hidden>
      <label
        className={isOn ? "selected" : ""}
        // htmlFor={item.id}
        aria-hidden
      >
        {item.label}
      </label>
    </li>
  );
};

PickerCell.propTypes = {
  item: itemProp,
  onChange: PropTypes.func,
  isOn: PropTypes.bool,
  key: PropTypes.string,
};

const Picker = ({ data, onChange, selected }) => (
  <PickerWrapper>
    <ul>
      {data.map((item) => (
        <PickerCell
          item={item}
          key={item.value}
          onChange={onChange}
          isOn={selected.includes(item.value)}
        />
      ))}
    </ul>
  </PickerWrapper>
);

Picker.propTypes = {
  data: PropTypes.arrayOf(itemProp),
  onChange: PropTypes.func,
  selected: PropTypes.object,
};

const Selector = (props) => {
  if (props.picker) return <Picker {...props} />; 
  else return <DropDownList {...props} />;
};

export const SelectFields = ({
  onClear,
  selected,
  onChange,
  onClose,
  data,
  fieldname,
  title,
  placeholder,
  picker,
}) => {
  const handleClose = (item) => onClose({ item, fieldName: fieldname });
  const handleClear = () => onClear({ fieldName: [fieldname] });
  const handleChange = (item) => onChange({ fieldName: fieldname, item });
  return (
    <DropDownWrapper title={title} active={selected[0] !== "*"}>
      <SelectedField
        onClose={handleClose}
        onClear={handleClear}
        selected={selected}
        placeholder={placeholder}
        data={data}
      />
      <Selector
        data={data}
        onChange={handleChange}
        selected={selected}
        picker={picker}
      />
    </DropDownWrapper>
  );
};

SelectFields.propTypes = {
  onClear: PropTypes.func,
  selected: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(itemProp),
  fieldname: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  picker: PropTypes.bool
};

SelectFields.defaultProps = {
  picker: false,
};
