import React, { useContext } from "react";

import { Page, Box } from "@shopify/polaris"
import { TitleBar } from "@shopify/app-bridge-react";



import Scheduler from "../components/Scheduler";
import { ShopContext } from "../contexts/ShopContext";
import { WPFooterHelp, LEARNMORE} from "../components/WPFrame.tsx"



const WPTitleBar = (props) => {
  const { isActive } = useContext(ShopContext);
  const host:string = shopify?.config?.host||'' 
  const subscription:string = "/subscription?host="+host
  return <TitleBar
    title="Schedule updates">
      {!(isActive) && 
        <a href={subscription}>Upgrade</a>
       }
    <section label="?">
      <a href={LEARNMORE} target="_blank">Learn more ...</a>
      <a href='mailto:support@wp-dataanalytics.de?subject=WP%20Price%20Updater' target="_blank">Contact support ...</a>
      <a href='mailto:support@wp-dataanalytics.de?subject=WP%20Price%20Updater:%20Feature%20request' target="_blank">Ask for feature ...</a>
    </section>

  </TitleBar>
}


type WPFrameProp = {
  children: any,
  title?: string,
  toastMarkup?: null|string,
  actionGroups?: Array<any>,
  primaryAction?: any,
  setOpenPlan?: (state: boolean) => void
}



const WPFrame = (props:WPFrameProp) => {
  const { children, toastMarkup, actionGroups, primaryAction } = props;
 
  return (
    <Page
     fullWidth
      primaryAction={primaryAction}
      actionGroups={actionGroups}
    >
      <WPTitleBar {...props} />
      <Box id="boxMF">
        {children}
      </Box>
      {toastMarkup}
      <WPFooterHelp />
    </Page>

  )
}



export default function Index() {
  return (
    <WPFrame title ="Schedule updates"> 
      {/* <div>Und hier</div> */}
      <Scheduler /> 
    </WPFrame>
  );
}
