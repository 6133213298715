import {
  Button,
  DescriptionList,
  Link,
  TextField,
  Tooltip,
  Spinner,
  SkeletonBodyText
} from "@shopify/polaris";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { GoogleContext, urlGoogleSheet } from "../../contexts/GoogleContext";
// import SheetsManager from "./SheetsManager";
// import { SignIn } from "./SignIn";


const GoogleUrlField = ({ googleurl, onChange, onSubmit, onClear, errormsg = "", isLoading=true }) => {
  if (errormsg === "" && googleurl.length > 0) {
    errormsg = urlGoogleSheet.test(googleurl) ? "" : "Invalid Google Sheet URL"
  }
  const SubmitButton = () =>
    <Button
      onClick={() => { onSubmit(googleurl) }}
      disabled={googleurl.length < 1}
      variant="primary">Submit</Button>

  return (
    <TextField
      autoComplete="off"
      label="Link to the Google Sheet"
      value={googleurl}
      onChange={onChange}
      placeholder="Insert link to a Google Sheet."
      error={errormsg}
      clearButton
      onClearButtonClick={onClear}
      loading= {isLoading}
      connectedRight={
        <SubmitButton />
        // <Button
        //   onClick={()=>{onSubmit(google.url)}}
        //   disabled={google.url.length < 1 && google?.file?.isAppAuthorized}
        //   variant="primary">Submit</Button>
      }
      
    />
  );
}

export const GoogleSheetSetup = () => {

  const { isLoading, google, setGoogle, file, sheet, valid, error, authorize } = useContext(GoogleContext);

  const [googleurl, setGoogleUrl] = useState<string>(google.url || '')
  //const [valid, setValid] = useState<boolean>(google_valid)


  useEffect(() => {
    if (!isLoading) {
      setGoogleUrl(google.url || '')
    }
  }, [google])


  const handleChange = useCallback((url) => {
    // setValid(urlGoogleSheet.test(url))
    setGoogleUrl(url)
  }, []);

  const handleClear = useCallback(() => {
    setGoogle("")
    setGoogleUrl('')
  }
    , []
  );

  const handleSubmit = useCallback((url) => {
    setGoogle(url)
  }, []);

  if (isLoading) {
    return (
       <GoogleUrlField googleurl="" onSubmit={handleSubmit} onChange={handleChange} onClear={handleClear} isLoading={isLoading}/>
    )
  }



  if (error) {
    let errormsg = error?.message
    return <>
      <GoogleUrlField googleurl={googleurl} onSubmit={handleSubmit} onChange={handleChange} onClear={handleClear} errormsg={errormsg} />
    </>
  }


  //let valid = urlGoogleSheet.test(googleurl) && googleurl.length>0
  if (!valid || !file || !sheet) {
    return <GoogleUrlField googleurl={googleurl} onSubmit={handleSubmit} onChange={handleChange} onClear={handleClear} />
  }

  return (
    <>
      <GoogleUrlField googleurl={googleurl} onSubmit={handleSubmit} onChange={handleChange} onClear={handleClear} errormsg={valid ? "" : "Invalid Google Sheet URL"} isLoading={isLoading}/>
      <DescriptionList
        items={[
          {
            term: "Spredsheet name",
            description: file?.name,
          },
          {
            term: "Last modified",
            description: file?.modifiedTime,
            // new Date(file?.modifiedTime).toString(),
          },
          {
            term: "Sheet title",
            description: sheet?.sheets?.find(
              (s) =>
                google?.sheetId &&
                s?.properties?.sheetId === parseInt(google?.sheetId)
            )?.properties?.title,
          },
          {
            term: "Range",
            description: JSON.stringify(google?.range),
          },

          {
            term: "Auto recalc",
            description: JSON.stringify(sheet?.properties?.autoRecalc),
          },
          {
            term: "Sheets",
            description: JSON.stringify(
              sheet?.sheets?.map((s) => s?.properties?.title)
            ),
          },
          {
            term: "Named ranges",
            description: JSON.stringify(
              sheet?.namedRanges?.map((s) => s?.name)
            ),
          },
          {
            term: "Permission",
            description: JSON.stringify(
              file?.permissions?.map((p) => ({
                id: p.anyone,
                type: p.type,
                role: p.role,
              }))
            ),
          },
        ]}
      />
    </>
  );
};
