import React, { useContext,useState } from "react";
import { WebsocketContext, WSMessage } from "../WebSocketContext";
import WPStates,{ WPStatus,PrettyPrint } from "../../WPState";
import { GoogleContext } from "../../../contexts/GoogleContext";
//import { Props } from "@shopify/app-bridge-react/components/ContextualSaveBar";


type WPStatesProp = {
  data: WSMessage|null,
  onDismiss: (state: boolean) => void,
  title: string,
  setUpdateData: (props: any) => void,
  props: any
}



const GoogleStates = (props: WPStatesProp) => {
  const { data,setUpdateData } = props;
  if (!data || Object.keys(data).length === 0) {
    return (
      <WPStatus {...props}>
        <p>Starting to export...</p>
      </WPStatus>
    );
  }

  if (data?.status === "ERROR") {
    let title= `Error: ${props?.title}`
    return (
      <WPStatus
        {...props}
        title={title}
        tone="critical"
      >
        {data?.msg && <p>{data.msg}</p> }
        {data?.error && <p>{data.error}</p> }
      </WPStatus>
    );
  }

  if (data?.status === "GOOGLE_ERROR") {
    let title= `Error: ${props?.title}`
    if (data.data){
      let { authorize_url } = data.data;
      if ( authorize_url)
        window.open(authorize_url,'popup','width=600,height=600')
    }

    return (
      <WPStatus
        {...props}
        title={title}
        tone="warning"
      >
         {data?.error && <p>{data.error}</p> }
      </WPStatus>
    );
  }


  if (data?.status === "RUNNING") {
    return (
      <WPStatus
        {...props}
        tone="info"
      >
        <p>{data?.msg}</p>
      </WPStatus>
    );
  }

  if (data?.status === "COMPLETED") {
    setUpdateData(data)
    return (
      <WPStatus
        {...props}
        title={data.msg}
        tone="success"
      >
      </WPStatus>
    );
  }

  return (
    <WPStatus {...props}>
      <PrettyPrint jsonObj={data} />
    </WPStatus>
  );
};


const UpdateFinished = (props) => {
  return <WPStatus
      {...props}
      title={props.msg}
      tone="success"
    >
    </WPStatus>
   }


// const GoogleTemplate = (props) => {
//   const [updateData, setUpdateData] = useState({});
//   const { setExport } = props;
//   const { setGoogle } = useContext(GoogleContext);
//   const [ready, message, send] = useContext(WebsocketContext); 
//   let title = "Exporting product variants to a Google sheet ";
//   if (message?.status === 'COMPLETED') {
//     //let title = `Updated the prices of ${msg?.variants} product (variants)`
//     let title = `Successfully exported the product variants to a Google Sheet`;
    
//     //setGoogle(updateData?.url);
//     return <WPStates
//       heading={title}
//       action={{
//         content: 'Close', onAction: () => {
//           setExport(null);
//         }
//       }}
//       secondaryAction={{
//         content: 'Open the Google Sheet',
//         url: updateData?.url,
//        // external: true
//       }}
//     >
//       <UpdateFinished data={updateData} {...props} />
//     </WPStates>
//   }
 
//   return (
//     <_WPStates data={message} onDismiss={() => setExport(false)} title={title} setUpdateData={setUpdateData} {...props}/>
//   );
// };

export default GoogleStates;
