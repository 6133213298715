import React,{useState, createContext,useContext, useEffect,useCallback } from "react";
import { ShopContext } from "./ShopContext";

const ADMIN_SERVER = import.meta.env.VITE_ADMIN_SERVER; 



type SubscriptionContextProps = {
  subscription: {},
  error: null,
  setSubscription: (update:boolean) => void
};  

const SubscriptionContext = createContext<SubscriptionContextProps>({});

type SubscriptionProviderProps = {
   children: React.ReactNode
}

export const createSubscription=async(shop:string,host:string)=>{
  let body= JSON.stringify({returnUrl: window.location.origin + `/api/auth?shop=${shop}&host=${host}`})
   return fetch(`${ADMIN_SERVER}/${shop}/subscription`, {
    method: "POST", 
    headers: {
      'Content-Type': 'application/json'
    },
    body: body})
  .then((res) => res.json())
  .then((res)=>res?.data?.appSubscriptionCreate?.confirmationUrl)
  .catch((err)=>{
    console.error(err)
    throw err
  })
}

export const cancelSubscription=async(shop:string,id:string)=>{
  return fetch(`${ADMIN_SERVER}/${shop}/subscription?id=${id}`, {method: "DELETE"})
  .then((res) => res.json())
  .then((res)=>res?.data?.appSubscriptionCancel?.appSubscription)
  .catch((err)=>{
    console.error(err)
    throw err
  })
}

function SubscriptionProvider({children}: SubscriptionProviderProps ) {
  const shop = shopify.config.shop;
  const host = shopify.config.host;

  const {shopData, setActive }=useContext(ShopContext)
  const [subscription, _setSubscription] = useState<{}>(()=>(shopData?.subscription||{}));
  const [error, setError] = useState(null)
  const [url] = useState(() => `${ADMIN_SERVER}/${shop}/subscription`);
  const [returnUrl] = useState(()=>window.location.origin + `/api/auth?shop=${shop}&host=${host}`);

  const setSubscription=async(upgrade:boolean)=>{
     if (upgrade){
      try {
        return await createSubscription(shop,host)
      } catch (error) {
        console.error(error)
        setError(error)
      }
      // let body= JSON.stringify({returnUrl: returnUrl})
      // const response = await fetch(url, {method: "POST", body: body});
      // let data=await response.json();    
      // return data?.data?.appSubscriptionCreate?.confirmationUrl
    } //else {
    let id = subscription[0]?.id
    const response = await fetch(url+`?id=${id}`, {method: "DELETE"});
    const data = await response.json();
    setActive(false)
    return data?.data?.appSubscriptionCancel?.appSubscription;
  } 
  return (
    <SubscriptionContext.Provider value={{ setSubscription, subscription,error}}>
      {children}
    </SubscriptionContext.Provider>
  );
}


export default SubscriptionContext;


export {SubscriptionProvider,SubscriptionContext,SubscriptionContextProps}
