import React from 'react'
import PropTypes from 'prop-types'
import {
  convertToCronSyntax,
} from '../utils/helper'

import cronstrue from 'cronstrue/i18n';
import {  TextField } from '@shopify/polaris';

const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';

const TextFieldForm = ({ select }) => {
  let locale = getNavigatorLanguage()
  const value = convertToCronSyntax(select)
  return (
      <TextField
        label='Current schedule setting'
        readOnly
        multiline={1}
        value={cronstrue.toString(value, { locale: locale })} 
        helpText={
          <>
            { Intl.DateTimeFormat().resolvedOptions().timeZone}
          </>
        }
        disabled
        style={{color: "black"}}
      /> 
  )
}

TextFieldForm.propTypes = {
  select: PropTypes.shape({
    min: PropTypes.string,
    hour: PropTypes.string,
    dom: PropTypes.string,
    mon: PropTypes.string,
    dow: PropTypes.string
  }),
  //select: PropTypes.object.isRequired
}

export default TextFieldForm
