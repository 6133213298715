import React,{useRef} from 'react';
//import styles from "../Guide.module.css";

const DropDownArrowIconLabel = () => (
  <span className="dropdown__icon-btn__label">
    <svg className="MuiSvgIcon-fontSizeSmall" viewBox="0 0 24 24" aria-hidden>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  </span>
);

const DropDownArrowIcon = () => (
  <button
    className="dropdown__arrow-icon"
    type="button"
    aria-label="Open"
    title="Open"
  >
    <span className="dropdown__icon-btn__label">
      <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden>
        <path d="M7 10l5 5 5-5z" />
      </svg>
    </span>
    <span className="btn-group__btn-foot" />
  </button>
);

const ClearButton = ({ onClick, isSelected }) => {
 return  <button
    onClick={onClick}
    className= { isSelected ? "btn-group__clear--active" : "btn-group__clear"}
    type="button"
    aria-label="Clear"
    title="Clear"
  >
    <DropDownArrowIconLabel />
  </button>
}

const BtnGroup = ({ isSelected, onClear }) => {
  return (
    <div className="btn-group">
      <ClearButton onClick={onClear} isSelected={isSelected} />
      <DropDownArrowIcon />
    </div>
  );
};

const DropDownClose = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="dropdown__close-icon"
    focusable={false}
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
  </svg>
);

const Selected = ({ item, index, onClose }) => (
  <div role="button" key={index} className="dropdown__input-wrapper">
    <span className="dropdown__selected text">
      {item.label}
    </span>
    <DropDownClose onClick={onClose} />
  </div>
);

const SelectedFieldIntern = ({
  selected,
  onClose,
  placeholder,
  data,
  isSelected
}) => {
  if (!isSelected) {
    return (
      <div 
        className="dropdown__selected placeholder"
      >
        {placeholder}
      </div>

    );
  }
  const selectedData = data.filter((item) => selected.includes(item.value));
  return (
       <div className="dropdown__selected">
        {selectedData.map((item, index) => (
          <Selected item={item} index={index} onClose={() => onClose(item)} />
        ))}
    </div>
  );
};


const DropDownCurrent = React.forwardRef((props, ref) => (

  <div ref={ref} className="dropdown__current" role="button" onClick={props.onClick}>
    {props.children}
  </div>
));

export const SelectedField = ({
    selected,
    onClose,
    onClear,
    placeholder,
    data,
  }) => {
    /*
            onClick={()=>{alert('click');    debugger;
            dropdown && dropdown.current && dropdown.current.focus()}}
  */   
 /*
    const dropdown = useRef(null)
    const isSelected = selected[0] !== "*";
    */
    const isSelected = selected[0] !== "*";
    const ref =React.createRef();

    const onClick=(e) => {
      // ref.current.focus()
     }

    return (
      <DropDownCurrent ref={ref} onClick={onClick}>
        <SelectedFieldIntern onClose={onClose} selected={selected} placeholder={placeholder} data={data} isSelected={isSelected}/>
        <BtnGroup onClear={onClear} isSelected={isSelected} />
      </DropDownCurrent>
    );
  };
  