import React,{useState, createContext, useEffect } from "react";
//import { ShopContext } from "./ShopContext";
import { ScheduleType } from "../types";

const SCHEDULE_SERVER = import.meta.env.VITE_SCHEDULE_SERVER;
const SERVER = import.meta.env.VITE_SERVER;
const WP_ACCESS_TOKEN = import.meta.env.VITE_WP_ACCESS_TOKEN;

type ScheduleContextProps = {
  schedule?: ScheduleType,
  setSchedule?: (data: ScheduleType) => void,
  error?: any,
};  


export const ScheduleContext = createContext<ScheduleContextProps>({});

export const getSchedule=async(shop:string)=>{
  return fetch(`https://${SCHEDULE_SERVER}/${shop}/schedule.json`,
           {headers: { "Content-Type": "application/json" }})
  .then((res) => res.json())
  .catch((err)=>{
    console.error(err)
    throw err
  })
}

export const postSchedule=async(shop:string,schedule:{})=>{
  return fetch(`https://${SCHEDULE_SERVER}/${shop}/schedule`, {method: "POST", body: JSON.stringify(schedule),
            headers: { "Content-Type": "application/json" }})
  .then((res) => res.json())  
  .catch((err)=>{
    console.error(err)
    throw err
  })
}

export const ScheduleContextProvider=({shop,children} )=>{
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [schedule, _setSchedule] = useState<ScheduleType>({min: '*', hour: '*', dom: '*', mon: '*', dow: '*'});
  const [error, setError] = useState(null);


  const update = (data: ScheduleType):ScheduleType => {
    fetch(`https://${SERVER}/${shop}/schedule`, {method: "POST", body: JSON.stringify(data)})
    .then((res) => res.json())
    .catch((err)=>setError(err))
  };


  const setSchedule = (data: ScheduleType) => {
    let res = update(data)
    _setSchedule(res);
  }

  useEffect(()=>{
    if (shop){
      fetch(`https://${SERVER}/${shop}/schedule.json`)
      .then((res) => res.json())
      .then((res)=>{
        _setSchedule(res)
        setIsLoading(false);
      })
      .catch((err)=>setError(err))
    }},[shop])

  return (
    <ScheduleContext.Provider value={{schedule, setSchedule,error}}>
      {children}
    </ScheduleContext.Provider>
  );
}


