import React,{ useContext,useCallback} from "react";
import WPStates,{WPStatus} from "../WPState";
import ExcelStates from "./XLS";
import GoogleStates from "./Google/Google";
import {WebsocketProvider, WebsocketContext} from "./WebSocketContext";
import { GoogleContext } from "../../contexts/GoogleContext";

const SERVER = import.meta.env.VITE_SERVER

const Exports = {
  Google: Symbol("googleExport"),
  Excel: Symbol("excelExport"),
};




const ExportGoogle = (props) => {  
  const {setExport} = props;
  const [ready, message, send] = useContext(WebsocketContext); 
  const { setGoogle } = useContext(GoogleContext);
  
  const handleGoogle=useCallback((googleurl) =>{
    setGoogle(googleurl);
  },[])

  let title = "Exporting product variants to a Google sheet ";
  if (message?.status === 'COMPLETED') {
    // handleGoogle(message?.url);
    return <WPStates
      heading={title}
      action={{
        content: 'Close', onAction: () => {         
          setExport(null);
          handleGoogle(message?.url)
        }
      }}
      secondaryAction={{
        content: 'Open the Google Sheet',
        url: message?.url,
       // external: true
      }}
      >
      <WPStatus  {...props} title={message.msg} status="success"> </WPStatus>
          
    </WPStates>
  }
  return <WPStates
    heading={title}
    action={{ content: 'Cancel', onAction: () => { setExport(null) } }}
    success={{action: { content: 'Close', onAction: () => { setExport(null) } }                                   
            }}
               
  //secondaryAction={secondaryAction}
  >
    <GoogleStates data={message} onDismiss={() => setExport(null)} title={title} {...props}     />
  </WPStates>
  
}


const ExportExcel = (props) => {  
  const {setExport} = props;
  const [ready, message, send] = useContext(WebsocketContext); 
  const onDownload = async (data) => {
    await fetch(`https://${SERVER}${data.url}`, {
      method: "POST",
      body: data.data,
    })
      .then((res) => res.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "variants.xlsx";
        link.click();

        window.URL.revokeObjectURL(link.href);
        props.setExport(null);
      });
  };
     
  let title = "Exporting product variants to a Excel sheet ";
  if (message?.status === 'COMPLETED') {
    let bulk = JSON.parse(message?.data||"{}")
    let title1 = `Exported ${bulk?.objectCount|| 0} product variants to Excel`;
    return <WPStates
      heading={title}
      action={{
        content: 'Close', onAction: () => {         
          setExport(null);
        }
      }}
      secondaryAction={{
        content: 'Click to download the Excel file',
        url: message?.url,
        onAction: ()=>onDownload(message)
      }}
      >
      <WPStatus
        {...props}
        status="success"
        title={title1}
      >
      </WPStatus>          
    </WPStates>
  }
  return <WPStates
    heading={title}
    action={{ content: 'Cancel', onAction: () => { setExport(null) } }}
    success={{action: { content: 'Close', onAction: () => { setExport(null) } }                                   
            }}
               
  //secondaryAction={secondaryAction}
  >
    <ExcelStates data={message} onDismiss={() => setExport(null)} title={title} {...props}     />
  </WPStates>
  
}


const ExportProducts = (props) => {
  const { shop,exportType } = props;
  switch (exportType) {
    case Exports.Excel:
      return (
        <WebsocketProvider shop={shop} query={exportType.description}>
              <ExportExcel {...props} />
         </WebsocketProvider>);
    case Exports.Google:
      return ( <WebsocketProvider shop={shop} query={exportType.description}>
                  <ExportGoogle {...props}/>  
              </WebsocketProvider>
             );
    default:
      return null;
  }
};

export { Exports, ExportProducts };
