import React, { createContext, useCallback, useEffect, useState } from "react";
import { GoogleType, GoogleDefault } from "../types";
// import { useGoogleLogin, CodeResponse } from '@react-oauth/google';

// import { gapi } from 'gapi-script';


const SERVER = import.meta.env.VITE_SERVER;

export * from "../types"

type AuthorizeJSON = {
  authorize_url: string,
  link: string,
  request: {
    link: string,
    query: JSON,
    session: JSON,
  },
  state: string
}

type GoogleContextProps = {
  isLoading: boolean,
  google: GoogleType,
  setGoogle: (url: string) => void,
  file: any,
  sheet: any,
  valid: boolean,
  error: any,
  authorize?: AuthorizeJSON | {},
  setError: (error: any) => void,
};


export const urlGoogleSheet = new RegExp(
  "(http|https)://docs.google.com/spreadsheets/d/([a-zA-Z0-9-_]+)/?",
  "i"
);

export const urlGoogleRange = new RegExp(
  "(http|https)://docs.google.com/spreadsheets/d/([a-zA-Z0-9-_]+)/edit#gid=(d+)?",
  "i"
);




export const GoogleContext = createContext<GoogleContextProps>({
  isLoading: false,
  google: { id: null, sheetId: null, range: null, sheetName: null, url: null, thumpnail: null },
  setGoogle: () => { },
  file: null,
  sheet: null,
  valid: false,
  error: null,
  authorize: {},
  setError: () => { }
});

type GoogleContextProviderProps = {
  children: React.ReactNode
}


export const GoogleContextProvider = ({ children }: GoogleContextProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [google, _setGoogle] = useState<GoogleType>(GoogleDefault);
  const [file, setFile] = useState();
  const [sheet, setSheet] = useState();
  const [error, setError] = useState();
  const [valid, setValid] = useState<boolean>(false);
  //const [authorize, setAuthorize] = useState<boolean>(false)
  const [authorize, setAuthorize] = useState<AuthorizeJSON|null>()

  const shop = shopify.config.shop;
  // const login = useGoogleLogin({
  //   onSuccess: codeResponse => handleLoginSuccess(codeResponse),
  //   onError: () => { },
  //   flow: 'auth-code',
  //   scope: 'https://www.googleapis.com/auth/drive'
  // });


  // const initClient = () => {
  //   gapi.client.init({
  //     apiKey: 'YOUR_API_KEY',
  //     clientId: 'YOUR_CLIENT_ID',
  //     discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  //     scope: 'https://www.googleapis.com/auth/spreadsheets'
  //   }).then(() => {
  //     // Listen for sign-in state changes.
  //     gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
  //   });
  // };

  const updateGoogle = (res) => {
    _setGoogle(res)
    setFile(res?.file)
    setSheet(res?.sheet)
    let google_url = res?.url || '';
    let valid_ = urlGoogleSheet.test(google_url) && res?.file !== undefined && res?.sheet !== undefined
    setValid(valid_)
  }

  const update_ = (url: string) => {
    setIsLoading(true);
    setError(undefined)
    setFile(undefined)
    setSheet(undefined)
    fetch(`https://${SERVER}/${shop}/google.json`, { method: "POST", body: JSON.stringify({ url: url }) })
      .then(async (res) => {
        if (!res.ok) {
          if (res.status === 401) {
            let data = await res.json()
            let { authorize_url } = data;
            window.open(authorize_url,'popup','width=600,height=600')
            // login()
            // setAuthorize(data)
            // the server should not return the authorzation data
            // updateGoogle(data);
            // console.log(message)
            // throw new Error("Unauthorized")

            setIsLoading(false)
            return 
          }
          if (res.status === 422) {
            let error = await res.json()
            throw new Error(error?.message)
          }
          if (res.status === 404) {
            let error = await res.json()
            throw new Error(error?.message?.message)
          }
          let error = await res.json()
          throw new Error(error?.detail);
        }
        let json = await res.json();
        updateGoogle(json);
        setIsLoading(false);
        // return res.json();  // Handle other types appropriately
      })
      .catch((err) => {
        console.error(err)
        setError(err)
      })
  };

  const update = useCallback((url) => update_(url), []);



  const setGoogle = useCallback((googleurl) => {
    console.debug(googleurl, google.url)
    //if (googleurl===google.url) return;
    if (googleurl === null || googleurl === undefined || googleurl === "") {
      update('')
      return
    }
    _setGoogle(google => ({ ...google, url: googleurl }))
    update(googleurl);
    // if (urlGoogleRange.test(googleurl)) {
    //   update(googleurl);
    // } else {
    //   updateGoogle({ id: null, sheetId: null, range: null, sheetName: null, url: googleurl })
    // }
  }, []);

  // const setGoogle = (url: string) => {
  //   update(url)
  // }

  useEffect(() => {
    if (shop) {
      let link = `https://${SERVER}/${shop}/google.json`
      fetch(link)
        .then(async (res) => {
          if (!res.ok) {
            if (res.status === 401) {
              let data = await res.json()
              setAuthorize(data)
              let { authorize_url } = data;
              window.open(authorize_url,'popup','width=600,height=600')
              // updateGoogle(data)
              setIsLoading(false)
              return
            }
            if (res.status === 422) {
              let message = await res.json()
              console.log(message)
              throw new Error(message.message)
            }
            throw new Error(res.statusText);
          }

          let json = await res.json();  // Handle other types appropriately
          updateGoogle(json)
          setIsLoading(false)
        })
        .catch(error => {
          switch (error.name) {
            case 'GoogleUnauthorized':
              console.error('Handle GoogleUnauthorized error:', error);
              setError(error)
              break;
            default:
              setError(error)
              console.error('Generic error handling:', error);
          }
        })
        .catch((err) => setError(err))
    }
  }, [shop])

  return (
    <GoogleContext.Provider value={{ isLoading, google, setGoogle, file, sheet, valid, error, authorize, setError }}>
      {children}
    </GoogleContext.Provider>
  );
}


