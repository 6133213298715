import {
    Card,
    SkeletonBodyText,
    SkeletonPage,
} from "@shopify/polaris";

import WPFrame from "./WPFrame";

type LoadingPageProp = {
    setOpenPlan?: (state: boolean) => void
  }
export const LoadingPage =({setOpenPlan}:LoadingPageProp)=>
    <WPFrame 
      setOpenPlan={setOpenPlan}>
      <SkeletonPage>
        <Card>
          <SkeletonBodyText />
        </Card>
      </SkeletonPage>
    </WPFrame>