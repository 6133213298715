import {
  Badge,
  Button,
  EmptySearchResult,
  Icon,
  IndexTable,
  BlockStack,
  InlineStack,
  ButtonGroup,
  Text,
  Tooltip
} from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";

import {
  ClipboardIcon, DeleteIcon
} from '@shopify/polaris-icons';
import { GoogleContext } from "../../contexts/GoogleContext";
import { ShopContext } from "../../contexts/ShopContext";

let SERVER = import.meta.env.VITE_SERVER;

type MetafieldsType = {
  key: string,
  value: string,
  namespace: string,
  value_type: string,
  owner_resource: string,
  description: string,
  updated_at: string,
}

const emptyStateMarkup = (
  <EmptySearchResult
    title={'No metafields yet'}
    description={'You have to create them first'}
    withIllustration
  />
);

const liquid = (m: MetafieldsType) => {
  let s= `{% assign ${m.key} = ${m.owner_resource}.metafields.${m.namespace}.${m.key} %} `
  return s
}

const CopyButton=({metafield})=>(
  <Tooltip content="Copy liquid string to clipboard">
 <Button
   icon={<Icon  
     source={ClipboardIcon}
     tone="warning"
   />}
   onClick={async()=>{await navigator.clipboard.writeText(liquid(metafield))}}


   variant="tertiary" />
</Tooltip>)

const DeleteButton=({metafield, onDelete})=>{

  return (
    <Tooltip content={`Delete the metafield ${metafield.key}`}>
      <Button
        icon={<Icon  
          source={DeleteIcon}
          tone="warning"
        />}
        onClick={()=>{onDelete(metafield.key)}}


        variant="plain"
        tone="critical" />
  </Tooltip>
  );
}

const _MetafieldsSetup = ({ metafields, onDelete}) => {
  
 
  const RowRest = ({ metafield }) => (<>
    <IndexTable.Cell>
      <Text variant="bodyMd" as="span">
        {liquid(metafield)}
      </Text>
    </IndexTable.Cell>
    <IndexTable.Cell>
      <CopyButton metafield={metafield} />
    </IndexTable.Cell>
    <IndexTable.Cell>
      <DeleteButton metafield={metafield} onDelete={onDelete} />
    </IndexTable.Cell>
  </>
  )

  const rowMarkup = metafields.map((m, index) => {
      if (m?.errors) {
        if (m.errors?.value) {
        return (
          <IndexTable.Row id={m.key} key={m.key} position={index} tone="subdued">
                  <IndexTable.Cell>
                     <Text variant="bodyMd" fontWeight="bold" tone="caution" as="span">
                     {m.key}
                  </Text>
                 </IndexTable.Cell>
                 <IndexTable.Cell>
                 <Badge tone="critical">{m.value}</Badge>
                </IndexTable.Cell>
                <IndexTable.Cell>
                <Text variant="bodyMd" as="span" alignment="center">
                   Not updated: Value {m.errors.value[0]} 
                </Text>
                </IndexTable.Cell>
                <RowRest metafield={m} />
                </IndexTable.Row>
        );
          }
          return <IndexTable.Row id={m.key} key={m.key} position={index}>
                <IndexTable.Cell>
                   <Text variant="bodyMd" fontWeight="bold" as="span">
                   {m.key}
                </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                   <Text variant="bodyMd" fontWeight="bold" as="span">
                   {JSON.stringify(m.errors)}
                </Text>
                </IndexTable.Cell>
              </IndexTable.Row>
        }
      return <IndexTable.Row
        id={m.key}
        key={m.key}
        //selected={selectedResources.includes(m.key)}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {m.key}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Text variant="bodyMd" as="span" alignment="center">
          {m.value}
        </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Text variant="bodyMd" as="span">
          {new Date(m.updated_at).toLocaleString()}
        </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text variant="bodyMd" as="span">
            {liquid(m)}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CopyButton metafield={m}/>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <DeleteButton metafield={m} onDelete={onDelete}/>
        </IndexTable.Cell>       
      </IndexTable.Row>
     }
    );
  

    return (
    <>
 
      <IndexTable
        // onSelectionChange={handleSelectionChange}
        itemCount={metafields.length}
        headings={[
          {title: 'Key'},
          {title: 'Value'},
          {title: 'Last updated'},
          {title: 'Use in Liquid'},        
          {title: 'Copy',tooltipContent: "Copy the liquid string to clipboard"},
          {title: 'Delete',tooltipContent: "Delete the metafield in the Shopify shop" },

        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    </>
  );
};

export const MetafieldsSetup = () => {

  const { shopName } = useContext(ShopContext);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const {google} = useContext(GoogleContext)

  const updateMetafields = async () => {
    await fetch(`https://${SERVER}/metafields/${shopName}/google?spreadsheetId=${google.id}&range_name=metafields`,
                {method:"POST",body:JSON.stringify({range:"metafields"})})
       .then(async(res) => {
            if (!res.ok) {
               if (res.status === 422) {
                  let error = await res.json()
                   throw new Error(error?.error)
                 }
                 if (res.status === 404) {
                  let error = await res.json()
                  throw new Error(error?.message?.message)
                 }
                throw new Error(res.statusText);
               }            
            return res.json()})         
       .then((data) => setData(data))        
       .catch((err) => setError(err));
  };
  const getMetafields = async () => {
    await fetch(`https://${SERVER}/metafields/${shopName}`)
      .then((response) => response.json())
      .then((data) => setData(data.metafields))
      .catch((err) => setError(err));
  };
  const deleteMetafield = async (key:string) => {
    await fetch(`https://${SERVER}/metafields/${shopName}?key=${key}`,{method:"DELETE"})
      .then(() => getMetafields())
      .catch((err) => setError(err));
  };
  
  useEffect(() => {
    getMetafields();
  }, []);

  return (
    <>
     <BlockStack gap="500">
          <BlockStack gap="200">
    <_MetafieldsSetup  metafields={data} onDelete={deleteMetafield}/>
    </BlockStack>
        <InlineStack align="end" direction="row-reverse">
            <Button
              onClick={() => {updateMetafields()}}
              accessibilityLabel="Update Metafields from Google Sheet"
              variant="primary"
            >
              Update Metafields from Google Sheet
            </Button>
        </InlineStack>
      </BlockStack>
    </>
);
  }

MetafieldsSetup.propTypes = {
 // onClose: PropTypes.func.isRequired,
};
