import React, { useCallback, useContext, useState } from "react";

import WPFrame from "../components/WPFrame";
import { ExportProducts } from "../components/exports";


import { LoadingPage } from "../components/LoadingPage";
import UpdatePrices from "../components/updateProgress";

import WPStates from "../components/WPState";
import { Exports } from "../components/exports";
import { GoogleContext } from "../contexts/GoogleContext";
import { ShopContext } from "../contexts/ShopContext";

import Plan from "../components/Subscription/Plan";
import {Icon} from '@shopify/polaris';
import {
  UploadIcon
} from '@shopify/polaris-icons';


import './index.css';

const REPORTS = import.meta.env.VITE_REPORTS;




export default function HomePage() {

  const emptyToastProps = { content: null };
  const [updatePrices, setUpdate] = useState<boolean>(false);
  const [exportVariants, setExport] = useState<any|null>(null);
  const [toastProps, setToastProps] = useState(emptyToastProps);
  const [openPlan, setOpenPlan] = useState<boolean>(false);


  const toastMarkup = toastProps.content && (
    shopify.toast.show(toastProps.content, {
      onDismiss: () => {setToastProps(emptyToastProps)}, // Dismiss logic
    }));
  
  const { shopName,isLoading, error: shopError } = useContext(ShopContext);
  const { google,isLoading:googleLoading } = useContext(GoogleContext);
 
  const handleExport = useCallback(
      (val) => setExport(val),
      []
    );

  
  if (isLoading) return <LoadingPage setOpenPlan={setOpenPlan}/> 

  if (!googleLoading && !google?.url){
    const {shop,host} =shopify.config
    window.location.href=`/get-started?shop=${shop}&host=${host}`
  }

  const actionGroups = [{
   title: 'Export products', 
   actions: [{ id: "googleExport", content: '... to Google sheet', onAction: () => { setExport(Exports.Google) } },
     { id: "excelExport", content: '... to Excel sheet', onAction: () => { setExport(Exports.Excel) } }
     ],
  icon: <Icon source={UploadIcon} />
   },    
  ];
  

  const Inner = () => {
    if (exportVariants !== null) {
      return <ExportProducts shop={shopName}
             exportType={exportVariants}
             setExport={handleExport}
      />
    }
    if (updatePrices) {
      return <UpdatePrices shop={shopName} google={google} setUpdate={setUpdate}/>
    }
   
    return <WPStates action={{ id: "updatePrices", content: 'Update prices', onAction: () => { setUpdate(true) } }}>
       <p>Use the power of Google Sheets to calculate and update your product prices.</p>
     </WPStates>
  }


 // @ts-ignore
 
 if (openPlan) {
  return (
    <Plan onClose={() => setOpenPlan(false)}  /> 
  )
 }
  return (
    <WPFrame 
       toastMarkup={toastMarkup}
       actionGroups={actionGroups}
       setOpenPlan={setOpenPlan}>
      <Inner />
    </WPFrame>
  );

}
