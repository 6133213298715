import React from "react";
import { TextContainer, Modal } from "@shopify/polaris";

import { Redirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";

export default function Plan() {
  const app = useAppBridge();
  const redirect = Redirect.create(app);
  const handleCancel = () => {
    redirect.dispatch(Redirect.Action.APP, "/");
  };

  const handleSubscribe = () => {
    const search = new URLSearchParams(window.location.search);
    const url = search.get("url");
    redirect.dispatch(Redirect.Action.REMOTE, url);
  };

  return (
    <div style={{ height: "500px" }}>
      <Modal
        open
        onClose={handleCancel}
        title="Approve subscription"
        primaryAction={{
          content: "Approve subscription",
          onAction: handleSubscribe,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCancel,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Your subscribtion is not active. Please approve subscription.</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}

